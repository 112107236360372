@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0px;
  padding: 0px;
}
body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.ql-editor strong {
  font-weight: bold !important;
}
.article-body img {
  margin: 5px 0px;
  border: solid 1px #f9f9f9;
  box-shadow: 10px 10px 5px #0000003f;
}

@layer base {
  ul,
  ol {
    list-style: revert;
    margin-left: 20px;
  }
}
